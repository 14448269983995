// Import the type for IntelliSense
/**
 * @typedef {import('@mui/x-date-pickers/themeAugmentation')} ThemeAugmentation
 */

import { createTheme } from "@mui/material";
// import "@mui/x-date-pickers/themeAugmentation";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#eb6e1e",
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    MuiStaticTimePicker: {},
  },
});
