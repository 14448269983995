import "./CustomInput.css";
import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { forwardRef } from "react";
import { IMaskInput } from "react-imask";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";

export function CustomInput(props) {
  const { className } = props;
  return (
    <TextField
      {...props}
      className={`custom-input ${className}`}
      variant="filled"
      fullWidth
      margin="dense"
    />
  );
}
export function CustomFormPhoneInput(props) {
  const { label } = props;
  return (
    <CustomContainer>
      <label>{label}</label>
      <CustomPhoneInput {...props} />
    </CustomContainer>
  );
}
export function CustomCommonInput(props) {
  return (
    <CustomContainer className={props.className}>
      <CustomInput {...props} />
    </CustomContainer>
  );
}
export function CustomPasswordInput(props) {
  return (
    <TextField
      {...props}
      className="custom-input"
      variant="filled"
      fullWidth
      margin="dense"
      type="password"
    />
  );
}
const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});
export function CustomPhoneNumberInput(props) {
  return (
    <TextField
      {...props}
      className="custom-input"
      variant="filled"
      fullWidth
      margin="dense"
      InputProps={{
        inputComponent: TextMaskCustom,
      }}
    />
  );
}
export function CustomFormPassInput(props) {
  const { label } = props;
  return (
    <CustomContainer>
      <label>{label}</label>
      <CustomPasswordInput {...props} />
    </CustomContainer>
  );
}
export function CustomFormInput(props) {
  const { label } = props;
  return (
    <CustomContainer>
      <label>{label}</label>
      <CustomInput {...props} data-clarity-unmask="true" />
    </CustomContainer>
  );
}

export function CustomDatePicker(props) {
  return (
    <DatePicker
      {...props}
      label="Check-in"
      disablePast
      slotProps={{
        popper: {
          className: "date-picker-popper",
        },
        textField: {
          className: "custom-input",
          margin: "dense",
          variant: "filled",
          fullWidth: true,
        },
        inputAdornment: {
          className: "date-picker-button",
        },
      }}
    />
  );
}

export function CustomAutocomplete(props) {
  const { label } = props;
  return (
    <Autocomplete
      {...props}
      multiple
      id="services"
      renderInput={(params) => (
        <TextField
          {...params}
          className="custom-input"
          variant="filled"
          margin="dense"
          label={label}
          fullWidth
        />
      )}
    />
  );
}
const CustomContainer = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
const CustomPhoneInput = styled(PhoneInput)`
  margin-bottom: 10px;
  .flag-dropdown {
    width: 60px;
    border-radius: 10px 0 0 10px !important;
    background-color: #d9d9d9 !important;
    border: 1px solid rgb(173, 173, 173) !important;
    transition: 0.2s;
    .open {
      border-radius: 10px 0 0 10px !important;
    }
    .selected-flag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px 0 0 10px !important;

      padding: 0;
      :hover {
        background-color: #adadad;
        transition: 0.2s;
      }
    }
  }
  .form-control {
    padding: 10px 15px 10px 75px !important;
    width: 100% !important;
    border-radius: 10px !important;
    height: auto !important;
    border: 1px solid #adadad !important;
  }
  :hover {
    .flag-dropdown,
    .form-control {
      border: 1px solid #4096ff !important;
    }
  }
`;
