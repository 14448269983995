import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import "./CustomButton.css";

export function CustomLoadingButton(props) {
  const { className = "", children } = props;
  return (
    <LoadingButton {...props} className={`custom-button ${className}`}>
      {children}
    </LoadingButton>
  );
}

export function CustomIconButton(props) {
  const { className = "", children, avatar } = props;
  return (
    <IconButton
      {...props}
      avatar={avatar}
      className={`custom-icon-button ${className}`}
    >
      {children}
    </IconButton>
  );
}
