import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Suspense, lazy } from "react";
import { AuthProvider } from "hooks/useAuth";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CustomLoader } from "components/common";
import { RecoilRoot } from "recoil";
import { theme } from "theme/theme";
import { ThemeProvider } from "@mui/material";

const RoutesComponent = lazy(() => import("routes"));

function App() {
  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <RecoilRoot>
            <AuthProvider>
              <Suspense fallback={<CustomLoader />}>
                <RoutesComponent />
              </Suspense>
            </AuthProvider>
          </RecoilRoot>
        </ThemeProvider>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
